<div class="auth-layout-container {{ (healthCheckState$ | async).stage | lowercase }}">
  <div class="main-container">
    <div class="dashboard-background">
      <div class="left-part">
        <div class="content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
