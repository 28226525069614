import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { selectHealthCheckEntityState } from '../../../health-check/+state/health-check.selectors';
import { IHealthCheckState } from '../../../health-check/+state/interfaces/health-check-state';
import { HealthCheckModule } from '../../../health-check/health-check.module';

@Component({
  selector: 'locumsnest-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  standalone: true,
  imports: [RouterModule, CommonModule, HealthCheckModule],
})
export class AuthLayoutComponent {
  public healthCheckState$: Observable<IHealthCheckState>;

  constructor(private store: Store) {
    this.healthCheckState$ = this.store.pipe(select(selectHealthCheckEntityState));
  }
}
